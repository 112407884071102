/**
 * Number with text
 * @param int
 * @param b
 * @returns {string}
 */
function numberToSpell(int,b) {
    if (b == null) {
        b = 1
    }

    if(b == 1 && int == 0){
        return 'nulla';
    }

    var s = '';
    if (int > 999){
        if (int > 2000){
        	if(int > 999999999){
                s += numberToSpell(Math.floor(int / 1000000000),2);
                s += 'milliárd-';

                s += numberToSpell(Math.floor(int % 1000000000 / 1000000),2);
                s += 'millió-';

                s += numberToSpell(Math.floor(int % 1000000 / 1000),2);
                s += 'ezer';
                if (int % 1000 != 0) {
                    s += '-';
                }

			}
			else{
                if (int > 999999){
                    s += numberToSpell(Math.floor(int / 1000000),2);
                    s += 'millió';
                    if (int % 1000000 != 0) {
                        s += '-';
                    }
                }
                if (int % 1000000 != 0) {
                    s += numberToSpell(Math.floor(int % 1000000 / 1000),2);
                    s += 'ezer';
                    if (int % 1000 != 0) {
                        s += '-';
                    }
                }
			}

        }
        else {
            if (int == 2000) {
                s += 'kétezer';
            } else {
                s += 'ezer';
            }
        }
    }
    if (int > 99) {
        var szazas = Math.floor(int % 1000 / 100);
        switch (szazas)
        {
            case 9: s += 'kilenc';
                break;
            case 8: s += 'nyolc';
                break
            case 7: s += 'hét';
                break
            case 6: s += 'hat';
                break
            case 5: s += 'öt';
                break
            case 4: s += 'négy';
                break
            case 3: s += 'három';
                break
            case 2: s += 'két';
                break
        }
        if (szazas > 0) {
            s += 'száz'
        }
    }
    var egyes = int % 10;
    if (int % 100 > 9) {
        var tizes = Math.floor(int % 100 / 10);
        switch(tizes) {
            case 1: if (egyes > 0) {
                s += 'tizen';
            } else {
                s += 'tíz';
            }
                break;
            case 2: if (egyes > 0) {
                s += 'huszon';
            } else {
                s += 'húsz';
            }
                break;
            case 3: s += 'harminc';
                break;
            case 4: s += 'negyven';
                break;
            case 5: s += 'ötven';
                break;
            case 6: s += 'hatvan';
                break;
            case 7: s += 'hetven';
                break;
            case 8: s += 'nyolcvan';
                break;
            case 9: s += 'kilencven';
                break;
        }
    }
    if (egyes > 0) {
        switch(egyes) {
            case 1: s += 'egy';
                break;
            case 2: if (b == 1) {
                s += 'kettő';
            } else {
                s += 'két';
            }
                break;
            case 3: s += 'három';
                break;
            case 4: s += 'négy';
                break;
            case 5: s += 'öt';
                break;
            case 6: s += 'hat';
                break;
            case 7: s += 'hét';
                break;
            case 8: s += 'nyolc';
                break;
            case 9: s += 'kilenc';
                break;
        }
    }
    return s
}

/**
 * Number.prototype.format(n, x, s, c)
 *
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
Number.prototype.format = function(n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~~n));

    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

$(document).ready(function(){

    if($('#move_date').length){
        $('#move_date').datepicker({
            language: 'hu',
            startDate: new Date()
        });
    }

    if($('.dp-date').length){
        $('.dp-date').datepicker({
            language: 'hu',
            startDate: new Date()
        });
    }

    if($('#deposit').length){
        $('#deposit').maskMoney({thousands:' ', allowZero:true, precision: 0, allowNegative: false, allowEmpty: true});
		$('#deposit').maskMoney('mask');

        if($('#hiddenDeposit').length){
            $('#deposit').keyup(function(e){
                $('#hiddenDeposit').val($('#deposit').val().split(' ').join(''));
            })
        }
    }

    if($('#down_payment_0').length){
        $('#down_payment_0').maskMoney({thousands:' ', allowZero:true, precision: 0, allowNegative: false, allowEmpty: true});
        if($('#down_payment_0').val() > 0){
            $('#down_payment_0').maskMoney('mask');
        }

        if($('#hidden_down_payment_0').length){
            $('#down_payment_0').keyup(function(e){
                $('#hidden_down_payment_0').val($('#down_payment_0').val().split(' ').join(''));
            })
        }
    }

    if($('#down_payment_1').length){
        $('#down_payment_1').maskMoney({thousands:' ', precision: 0, allowNegative: false, allowZero: true, allowEmpty: true});

        if($('#down_payment_1').val() > 0){
            $('#down_payment_1').maskMoney('mask');
        }


        if($('#hidden_down_payment_1').length){
            $('#down_payment_1').keyup(function(e){
                $('#hidden_down_payment_1').val($('#down_payment_1').val().split(' ').join(''));
            })
        }
    }

    if($('#down_payment_2').length){
        $('#down_payment_2').maskMoney({thousands:' ', allowZero:true, precision: 0, allowNegative: false, allowEmpty: true});
        $('#down_payment_2').maskMoney('mask');

        if($('#hidden_down_payment_2').length){
            $('#down_payment_2').keyup(function(e){
                $('#hidden_down_payment_2').val($('#down_payment_2').val().split(' ').join(''));
            })
        }
    }

    if($('.birthdate').length){
        $('.birthdate').datepicker({
            language: 'hu',
            endDate: $(this).data('end')
        });
    }

    if ($('#down_payments').length) {
		$('#down_payments').change(function() {
			var $this = $(this);
			if ($this.is(':checked')) {
			    $('.down-payments').removeClass('d-none');
			    $('.last-down_payments-chbox').removeClass('d-none');
			    $('.dp-second').removeClass('d-none');
			} else {
				$('.down-payments').addClass('d-none').find('.clear-button').click();
				$('.last-down_payments-chbox').addClass('d-none');
				if($('#last-down_payments').prop('checked')) {
					$('#last-down_payments').click();
				}
                $('.dp-second').addClass('d-none');
            }
		});

		if ($('#down_payments').is(':checked')) {
			$('.down-payments').removeClass('d-none');
			$('.last-down_payments-chbox').removeClass('d-none');
		}
		else{
		    $('.dp-second').addClass('d-none');
        }

        if(!$('#last-down_payments').is(':checked')){
		    $('.dp-last').addClass('d-none');
        }
    }

    if ($('#last-down_payments').length) {
		$('#last-down_payments').change(function() {
			var $this = $(this);
			if ($this.prop('checked')) {
                $('.last-down-payments').removeClass('d-none');
                // $('#loan_dp_2').prop('checked', false);
			} else {
				$('.last-down-payments').addClass('d-none').find('.clear-button').click();
            }
		});

		if ($('#last-down_payments').prop('checked')) {
			$('.last-down-payments').removeClass('d-none');
		}
    }

    if($('.pdf-content').length){

        var priceText = numberToSpell(parseInt($('.pdf_price').text().split(' ').join('')), null);
        $('.pdf_price_text').html(priceText);

        var depositText = numberToSpell(parseInt($('.pdf_deposit').text().split(' ').join('')), null);
        $('.pdf_deposit_text').html(depositText);

        if($('#hidden_down_payment_0').length){
            var dp0text = numberToSpell(parseInt($('#hidden_down_payment_0').val().split(' ').join('')), null);
            $('.pdf_down_payment_0_text').html(dp0text);

            var dp1text = numberToSpell(parseInt($('#hidden_down_payment_1').val().split(' ').join('')), null);
            $('.pdf_down_payment_1_text').html(dp1text);

            var dp2text = numberToSpell(parseInt($('#hidden_down_payment_2').val().split(' ').join('')), null);
            $('.pdf_down_payment_2_text').html(dp2text);
        }
        else{
            var dp0text = numberToSpell(parseInt($('.pdf_down_payment_0').text().split(' ').join('')), null);
            $('.pdf_down_payment_0_text').html(dp0text);

            var dp1text = numberToSpell(parseInt($('.pdf_down_payment_1').text().split(' ').join('')), null);
            $('.pdf_down_payment_1_text').html(dp1text);

            var dp2text = numberToSpell(parseInt($('.pdf_down_payment_2').text().split(' ').join('')), null);
            $('.pdf_down_payment_2_text').html(dp2text);
        }

     }

    if($('#dealForm').length){

        $('#dealForm').find('input, select, checkbox').on('change focusin click', function(){
            $(this).parents('.form-group').removeClass('error');
        });

        var simpleFields = ['#passport_id', '#birthname', '#mothers_name', '#vat_number', '#personal_number', '#citizenship', '#move_date'];

    	if ($('#deal_firstname, #deal_lastname').val() !== '') {
			$('.pdf_name').html($('#deal_lastname').val() + ' ' + $('#deal_firstname').val());
			$('.pdf_signature').html($('#deal_lastname').val() + ' ' + $('#deal_firstname').val());
		}

    	$('#deal_firstname, #deal_lastname').on('input propertychange', function () {
            if($(this).closest('.form-group').hasClass('error')){
                $(this).closest('.form-group').removeClass('error');
            }
			$('.pdf_name').html($('#deal_lastname').val() + ' ' + $('#deal_firstname').val());
			$('.pdf_signature').html($('#deal_lastname').val() + ' ' + $('#deal_firstname').val());
		});

    	for(var i = 0; i < simpleFields.length; i++){
            if ($(simpleFields[i]).val() !== '') {
                var $this = $(simpleFields[i]);
                $('.pdf_'+$this.attr('id')).html($this.val());
            }
        }

        if ($('#birthdate').val() !== '' && $('#birthplace').val() != '') {
            var $this = $('#birthplace');

            $('.pdf_'+$this.attr('id')).html($this.val()+', '+$('#birthdate').val());
        }

		$('#vat_number, #passport_id, #birthname, #birthdate, #personal_number, #mothers_name').keyup(function(){
            var $this = $(this);
		    if($this.closest('.form-group').hasClass('error') && $this.val() != ''){
                $this.closest('.form-group').removeClass('error');
            }
        });

		if ($('#price').val() !== '') {
			var $this = $('#price');
            if($('#currency').val() == 'huf' || !$('#currency').val()){
                $('.pdf_'+$this.attr('id')).html(parseInt($this.val().split(' ').join('')).format(0, 3, ' ', ','));
            }
            else{
                $('.pdf_'+$this.attr('id')).html(parseInt($this.val().split(' ').join('')).format(2, 3, ' ', ','));
            }

            $('.pdf_price_text').html(numberToSpell(parseInt($this.val().split(' ').join('')), null));
		}

        if ($('#deposit').val() !== '') {
            var $this = $('#deposit');
            if($('#currency').val() == 'huf' || !$('#currency').val()){
                $('.pdf_deposit').html(parseInt($this.val().split(' ').join('')).format(0, 3, ' ', ','));
            }
            else{
                $('.pdf_deposit').html(parseInt($this.val().split(' ').join('')).format(2, 3, ' ', ','));
            }

            $('.pdf_deposit_text').html(numberToSpell(parseInt($this.val().split(' ').join('')), null));
        }


    	$('#passport_id, #birthname, #birthdate, #vat_number, #personal_number, #citizenship, #mothers_name, #price, #deposit, #move_date, .loan_provider, .loan_provider_2').on('input propertychange change', function () {
    		var $this = $(this);
            if($this.closest('.form-group').hasClass('error')){
                $this.closest('.form-group').removeClass('error');
            }
    		if($this.attr('id') == 'price' || $this.attr('id') == 'deposit'){
    			if($('#currency').val() == 'huf' || !$('#currency').val()){
                    $('.pdf_'+$this.attr('id')).html(parseInt($this.val().split(' ').join('')).format(0, 3, ' ', ','));
				}
				else{
                    $('.pdf_'+$this.attr('id')).html(parseInt($this.val().split(' ').join('')).format(2, 3, ' ', ','));
				}

				if(!$('#down_payments').is(':checked') && $this.attr('id') == 'price'){
                    $('.pdf_down_payment_0').html(parseInt($this.val().split(' ').join('')).format(2, 3, ' ', ','));
                    $('.pdf_down_payment_0_text').html(numberToSpell(parseInt($this.val().split(' ').join('')), null) + '');
                }

				$('.pdf_'+$this.attr('id')+'_text').html(numberToSpell(parseInt($this.val().split(' ').join('')), null) + '');
			}
			else{
                $('.pdf_'+$this.attr('id')).html($this.val());
			}
		});

        $('#birthdate, #birthplace').on('input propertychange change', function () {
            if ($('#birthdate').val() !== '' && $('#birthplace').val() !== '') {
                var $this = $('#birthplace');

                $('.pdf_'+$this.attr('id')).html($this.val()+', '+$('#birthdate').val());
            }
        });

        $('#loan_dp_1').change(function(e){
            if($(this).is(':checked')){
                $('.pdf-loan-provider-dp-1-span, .loan-provider-dp-1-container').removeClass('d-none');
                $('.loan-provider-container-dp-1').removeClass('d-none');
            }
            else{
                $('.pdf-loan-provider-dp-1-span, .loan-provider-dp-1-container').addClass('d-none');
                $('.loan-provider-container-dp-1').addClass('d-none');
            }
        });


        $('#loan_dp_2').change(function(e){
            if($(this).is(':checked')){
                $('.pdf-loan-provider-dp-2-span, .loan-provider-dp-2-container').removeClass('d-none');
                $('.loan-provider-container-dp-2').removeClass('d-none');
            }
            else{
                $('.pdf-loan-provider-dp-2-span, .loan-provider-dp-2-container').addClass('d-none');
                $('.loan-provider-container-dp-2').addClass('d-none');
            }
        });

        $('#loan_provider').keyup(function(e){
            $('.pdf_loan_provider').html($(this).val());ű
        });

        $('#loan_provider_2').keyup(function(e){
            $('.pdf_loan_provider_2').html($(this).val());
        });


    	$('#last-down_payments').change(function(e){
    	    if($(this).is(':checked')){
    	        $('.dp-last').removeClass('d-none');
            }
            else{
                $('.dp-last').addClass('d-none');
            }
        });

    	// handle down payments
    	$('#down_payment_0, #down_payment_1, #down_payment_2').on('input propertychange change', function () {
            var $this = $(this);
            if(!$('#down_payments').is(':checked')){
                return false;
            }

            if(parseInt($this.val()) == 0){
                $('.pdf_'+$this.attr('id')).html($this.val().split(' ').join(''));
            }
            else{
                $('.pdf_'+$this.attr('id')).html(parseInt($this.val().split(' ').join('')).format(0, 3, ' '));
            }
            if($this.val() !== ''){
                $('.pdf_'+$this.attr('id')+'_text').html(numberToSpell(parseInt($this.val().split(' ').join('')), null) + '');
            }

        });

    	$('#down_payment_deadline_0, #down_payment_deadline_1, #down_payment_deadline_2').on('input propertychange change keyup', function(){
    	    var $this = $(this);

            $('.pdf_'+$this.attr('id')).html($this.val());
        });

		$this = $('#currency');
		var text = '';
		if ($this.val() == 'huf') {
			text = ' Ft';
		} else if ($this.val() == 'eur') {
			text = ' €';
		} else if ($this.val() == 'usd') {
			text = ' $';
		}
		$('.pdf_'+$this.attr('id')).html(text);

		$('#currency').on('change', function () {
			$this = $(this);
            if($this.closest('.form-group').hasClass('error')){
                $this.closest('.form-group').removeClass('error');
            }
			var text = '';
			if ($this.val() == 'huf') {
				text = ' Ft';
			} else if ($this.val() == 'eur') {
				text = ' €';
			} else if ($this.val() == 'usd') {
				text = ' $';
			}
			$('.pdf_'+$this.attr('id')).html(text);
		});

		if ($('#country_id, #city, #address, #zip').value !== '') {
			var address = '';
			address += $('#zip').val() + ' ' + $('#city').val() + ', ' +$('#address').val();
			$('.pdf_address').html(address)
		}

		$('#country_id, #city, #address, #zip').on('input propertychange change', function () {
			if($(this).closest('.form-group').hasClass('error')){
                $(this).closest('.form-group').removeClass('error');
			}
			var address = '';
			address += $('#zip').val() + ' ' + $('#city').val() + ', ' +$('#address').val();
            $('.pdf_address').html(address)
		});

        $('textarea#note').on('keyup',function () {
            if($(this).closest('.form-group').hasClass('error')){
                $(this).closest('.form-group').removeClass('error');
            }
            var $this = $(this);
            // $('.pdf_'+$(this).attr('id')).html($this.val());
            $('.pdf_'+$(this).attr('id')).html($this.val().replace(/\r?\n/g,'<br/>'));

        });
	}

    /**
	 * Validate deal
     */
	if($('#showDealBtn').length){
        $('#showDealBtn').click(function(e){
        	var $form = $('#dealForm');
        	var url = $(this).data('href');

        	$form.find('.form-group').removeClass('error').find('p.error-response').html('');

            if(!$('#last-down_payments').is(':checked')){
                $form.find("#down_payment_2").val('');
                $form.find("#hidden_down_payment_2").val('');
                $form.find("#down_payment_deadline_2").val('');
            }

        	$.ajax({
				url: url,
				method: 'post',
				data: $form.serialize(),
				success: function(response){
					if(response.status == 'ok'){
                        $('#dealModal').modal('show');
					}
					else{
                        toastr.error('Hiba történt, kérjük próbálja újra');
					}
					return;
				},
				error: function(response){
                    if(response.status === 422){
                        var response = response.responseJSON;
                        for(var k in response.errors){
                            if(k.search('dp') >= 0){
                                $('.form-group.' + k).addClass('error').find('p.error-response').html(response.errors[k][0]);
                            }
                            else{
                                $('#'+k).closest('.form-group').addClass('error').find('p.error-response').html(response.errors[k][0]);
                            }
                        }

                        if ($('.form-group.error').length > 0) {
                            var first_position = $('.form-group.error').first().offset().top;
                            $('html, body').animate({
                                scrollTop: first_position - 200
                            }, 500);
                        }
                        return;
                    }

                    if(response.message){
                        toastr.error(response.message);
                    }
                    else{
                        toastr.error('Hiba történt, kérjük próbálja újra');
                    }
				}
			})
		})
	}

    if($('#deal_terms').length){
        $('#deal_terms').change(function(){
			if($(this).closest('.form-group').hasClass('error')){
                $(this).closest('.form-group').removeClass('error');
			}
		})
	}

    /**
	 * Send deal
     */
	if($('#sendDealBtn').length){
		$('#sendDealBtn').click(function(e){
			if(!$('#deal_terms').is(':checked')){
				$('#deal_terms').closest('.form-group').addClass('error');
				return;
			}

            $('#dealForm').submit();
            loader(true, '#sendDealBtn');
		})
	}

    if($('#sendCounterOfferBtn').length){
        $('#sendCounterOfferBtn').click(function(e){
            if(!$('#deal_terms').is(':checked')){
                $('#deal_terms').closest('.form-group').addClass('error');
                return;
            }

            $('#dealModal').modal('hide');

            bootbox.confirm({
                title: 'Eladói nyilatkozat',
                message: 'Az ajánlat címzettje, mint eladó kijelenti, hogy a jelen ajánlatot elolvasta, értelmezte és az ellenajánlatban foglalt feltételekkel módosított tartalommal elfogadja. Tudomásul veszi, hogy az ajánlattevő és közötte az adásvétel az ellenajánlatnak az ajánlattevő általi elfogadásával, az abban foglalt tartalommal létrejön, és vállalja a jogszabályi előírásoknak megfelelő adásvételi szerződés megkötését. Kijelenti továbbá, hogy az ellenajánlat megtételére és az abban foglalt feltételekkel módosított ajánlat elfogadására az Ingatlan tulajdonosaként, vagy a tulajdonos(ok) megbízott képviselőjeként jogosult.',
                buttons: {
                    confirm: {
                        label: 'OK',
                        className: 'btn btn-secondary'
                    },
                    cancel: {
                        label: 'Mégse',
                        className: 'btn btn-secondary-outline'
                    }
                },
                callback: function(r){
                    if(r === true){
                        $('#dealForm').submit();
                    }
                }
            });


        })
    }

    if ($('#declineDeal').length) {
        $('#declineDeal').click(function(e){
            var $this = $(this);
            e.preventDefault();

            var msg = $(this).data('msg');

            var title = 'Eladói nyilatkozat';

            if($this.data('title')){
                title = $this.data('title');
            }

            bootbox.confirm({
                title: title,
                message: msg,
                buttons: {
                    confirm: {
                        label: 'OK',
                        className: 'btn btn-secondary'
                    },
                    cancel: {
                        label: 'Mégse',
                        className: 'btn btn-secondary-outline'
                    }
                },
                callback: function(r){
                    if(r === true){
                        $this.parent().submit();
                    }
                }
            });
        });
    }

    if ($('#acceptDeal').length) {
        $('#acceptDeal').click(function(e){
            var $this = $(this);
            e.preventDefault();

            var msg = $(this).data('msg');

            var title = 'Eladói nyilatkozat';

            if($this.data('title')){
                title = $this.data('title');
            }

            bootbox.confirm({
                title: title,
                message: msg,
                buttons: {
                    confirm: {
                        label: 'OK',
                        className: 'btn btn-secondary'
                    },
                    cancel: {
                        label: 'Mégse',
                        className: 'btn btn-secondary-outline'
                    }
                },
                callback: function(r){
                    if(r === true){
                        $this.parent().submit();
                    }
                }
            });
        });
    }

    if ($('#counterOffer').length) {
        $('#counterOffer').click(function(e){
            var $this = $(this);
            e.preventDefault();

            var msg = $(this).data('msg');

            bootbox.confirm({
                title: 'Eladói nyilatkozat',
                message: msg,
                buttons: {
                    confirm: {
                        label: 'OK',
                        className: 'btn btn-secondary'
                    },
                    cancel: {
                        label: 'Mégse',
                        className: 'btn btn-secondary-outline'
                    }
                },
                callback: function(r){
                    if(r === true){
                        $this.parent().submit();
                    }
                }
            });
        });
    }

    if ($('.withdraw-deal').length) {
        $('.withdraw-deal').click(function(e){
            var $this = $(this);
            e.preventDefault();

            bootbox.confirm({
                title: 'Ajánlat visszavonása',
                message: 'Biztosan visszavonja az ajánlatot?',
                buttons: {
                    confirm: {
                        label: 'OK',
                        className: 'btn btn-secondary'
                    },
                    cancel: {
                        label: 'Mégse',
                        className: 'btn btn-secondary-outline'
                    }
                },
                callback: function(r){
                    if(r === true){
                        $this.parent().submit();
                    }
                }
            });
        });
    }

    /**
     * Filter business hours by location
     */
	if($('.btn-select-location').length){
	    $('.btn-select-location').click(function(e){
	        e.preventDefault();

	        var location = $(this).data('location');

	        $('#selected_location').val(location);
	        $('.selected-location').html(location);
	        $('.timetable-form').addClass('d-none');

	        $.ajax({
                url: '/deals/available-times',
                method: 'post',
                data: {
                    location: location
                },
                success:function(response){
                    if(response.status == 'ok'){
                        $('.timetable-container').html(response.html);
                        $('.timetable-wrapper.lawyer').slick({
                            infinite: false,
                            slidesToShow: 7,
                            slidesToScroll: 7,
                            arrows: true,
                            dots: false,
                            responsive: [
                                {
                                  breakpoint: 992,
                                  settings: {
                                    infinite: false,
                                    slidesToShow: 7,
                                    slidesToScroll: 7,
                                    arrows: false,
                                    dots: true
                                  }
                                },

                              ]
                        });

                        $('.day-wrapper .hour').click(function(e){
                            e.preventDefault();
                            var $this = $(this);

                            if($this.hasClass('disabled')){
                                return;
                            }

                            $('.day-wrapper .hour.selected').removeClass('selected');

                            $this.addClass('selected');
                            $('#selected_time').val($this.data('datetime'));

                            $('.selected-time').html($this.data('datetime'));

                            $('.timetable-form').removeClass('d-none').find('input[type=submit]').removeClass('disabled');
                        })
                    }
                },
                error: function(response) {
                    console.log(response);
                }
            })
        })
    }

    if($('#lawyerSelectForm').length){

	    $('.lawyer-card').click(function(e){
            $('#selected_site').val($(this).data('site-id'));
            // $('#lawyerSelectForm').find('input[type=submit]').removeClass('disabled');
        });

	    $('#lawyerSelectForm').submit(function(e){
            // if($('#selected_site').val().trim() == ''){
	         //    bootbox.alert('Az ügyvéd kiválasztása kötelező!');
	         //    return false;
            // }

            return true;

        });
    }

    if($('.add-new-liability').length){
        $('.add-new-liability').click(function(e){
            e.preventDefault();

            var $lastRoom = $('.liabilities-repeat-container').find('.liabilities-repeat');

            var repeatNum = parseInt($lastRoom.attr('data-repeat')) + 1;

            var $clonedElement = $lastRoom.clone();

            $clonedElement.removeClass('d-none').removeClass('liabilities-repeat');

            var $replaced = replaceAll($clonedElement.prop('outerHTML').toString(), '%s', repeatNum);

            $replaced = $($replaced);

            $('.liabilities-repeat').attr('data-repeat', repeatNum.toString());

            $('.liabilities-repeat-container').append($replaced);
        })
    }

    if($('.add-new-accessory').length){
        $('.add-new-accessory').click(function(e){
            e.preventDefault();

            var $lastRoom = $('.accessories-repeat-container').find('.accessories-repeat');

            var repeatNum = parseInt($lastRoom.attr('data-repeat')) + 1;

            var $clonedElement = $lastRoom.clone();

            $clonedElement.removeClass('d-none').removeClass('accessories-repeat');

            var $replaced = replaceAll($clonedElement.prop('outerHTML').toString(), '%s', repeatNum);

            $replaced = $($replaced);

            $('.accessories-repeat').attr('data-repeat', repeatNum.toString());

            $('.accessories-repeat-container').append($replaced);
        })
    }
});

$(document).on('click', '.remove-liabilities-btn', function(e){
    e.preventDefault();
    if($('.remove-liabilities-btn').length > 2){
        $(this).parent().parent().remove();
        copyLiabilities();
    }
});

$(document).on('click', '.remove-accessories-btn', function(e){
    e.preventDefault();
    if($('.remove-accessories-btn').length > 2){
        $(this).parent().parent().remove();
        copyAccessories();
    }
});

$(document).on('keyup', '.accessory-name', function(){
    copyAccessories();
});

$(document).on('keyup', '.liability-name, .liability-price', function(){
    copyLiabilities();
});


function copyAccessories(){
    var accessories = '';
    $('.accessory-name').each(function(){
        if($(this).val().trim() != ''){
            accessories += $(this).val()+'<br/>';
        }
    });

    $('.pdf_accessories').html(accessories);
}

function copyLiabilities(){
    var liabilities = '';

    $('.liabilities-repeat-container').find('.row').each(function(){
        var $this = $(this);

        if($this.find('.liability-name').val().trim() != '' && $this.find('.liability-price').val().trim() != ''){
            liabilities += $this.find('.liability-name').val() + ' - ' + $this.find('.liability-price').val() + ' Ft<br/>';
        }
    });

    $('.pdf_liabilities').html(liabilities);
}
