$(function () {
    var formTimeout = null;
    var formInterval = null;
    var counter = 0;

    //Timetable slick
    if ($('.timetable-wrapper.lawyer').length) {
        var initSlide = 0;

        if ($('.day-wrapper.today').length) {
            initSlide = $('.day-wrapper.today').data('index');
        }

        $('.timetable-wrapper.lawyer').slick({
            infinite: false,
            slidesToShow: 7,
            slidesToScroll: 7,
            arrows: true,
            dots: false,
            initialSlide: parseInt(initSlide),
            responsive: [{
                    breakpoint: 992,
                    settings: {
                        infinite: false,
                        slidesToShow: 7,
                        slidesToScroll: 7,
                        arrows: false,
                        dots: true
                    }
                },

            ]
        });


        $('.day-wrapper .hour.enabled, .day-wrapper .hour.disabled').on('click', function () {
            var dateTime = $(this).data('datetime');
            var key = $(this).data('key');
            var enabled = $(this).hasClass('enabled');

            if ($('.timetable-wrapper.lawyer').hasClass('move-hour')) {
                if ($(this).data('href')) {
                    var url = $(this).data('href');
                    bootbox.confirm({
                        title: 'Biztosan módosítja az eset időpontját?',
                        message: ' ',
                        buttons: {
                            confirm: {
                                label: 'OK',
                            },
                            cancel: {
                                label: 'Mégse',
                            }
                        },
                        callback: function (r) {
                            if (r === true) {
                                $('.loader').show();
                                $('#moveForm').attr('action', url).submit();
                            }
                        }
                    });
                }
            }

            $('#statusChangeModal').find('#bhKey').val(key);
            $('#statusChangeModal').find('#bhDatetime').val(dateTime);
            $('#statusChangeModal').find('#bhDateTime').html(dateTime);
            $('#statusChangeModal').find('#bhStatus').val(enabled ? 'enabled' : 'disabled');

            $('#statusChangeModal').modal('show');
        });

        $('.day-wrapper .hour.pending, .day-wrapper .hour.taken').on('click', function () {
            var $this = $(this);
            var url = $this.data('href');
            var key = $this.data('key');

            $('.loader').show();
            $.ajax({
                url: url,
                method: 'POST',
                data: {
                    key: key
                },
                success: function (response) {
                    if (response.status == 'ok') {
                        $('#bhInfoModalBody').html(response.html); //.modal('show');
                        $('#bhInfoModal').modal('show');
                    } else {
                        toastr.error(response.message && response.message != '' ? response.message : 'Hiba történt, kérjük frissítsd az oldalt és próbáld meg újra.');
                    }
                    $('.loader').hide();
                },
                error: function (response) {
                    if (response.message) {
                        toastr.error(response.message && response.message != '' ? response.message : 'Hiba történt, kérjük frissítsd az oldalt és próbáld meg újra.');
                    } else {
                        toastr.error('Hiba történt, kérjük próbálja újra.');
                    }
                    $('.loader').hide();
                }
            })
        });

        $('#saveBhStatusChange').click(function (e) {
            e.preventDefault();
            $('#statusChangeForm').submit();
        });

        $('.openBhModal').click(function (e) {
            e.preventDefault();
            $('.day-wrapper .hour[data-key=' + $(this).data('key') + ']').click();
        });

        /**
         * Open response modal
         */
        $('.open-response-modal').click(function (e) {
            e.preventDefault();

            var id = $(this).data('id');

            $('#responseForm').find('input[name=deal_id]').val(id);

            $('#responseModal').modal('show');
        });

        /**
         * Save response form
         */
        $('#saveResponseForm').click(function (e) {
            e.preventDefault();

            $('#responseModal').modal('hide');
            var $form = $('#responseForm');

            $(this).prop('disabled', true).addClass('disabled').find('i').removeClass('d-none');

            bootbox.confirm({
                title: 'Biztosan elmenti a változtatásokat?',
                message: '<small>A mentés nem visszavonható.</small>',
                buttons: {
                    confirm: {
                        label: 'OK',
                        className: 'btn btn-secondary'
                    },
                    cancel: {
                        label: 'Mégse',
                        className: 'btn btn-secondary-outline',
                    }
                },
                callback: function (r) {
                    if (r === true) {
                        $('.loader').show();
                        $.ajax({
                            url: $form.attr('action'),
                            method: 'POST',
                            data: $form.serialize(),
                            success: function (response) {
                                if (response.status == 'ok') {
                                    toastr.success(response.message);
                                    $('#responseForm').find('input[name=deal_id]').val('');
                                    $('#responseModal').modal('hide');
                                    location.reload();
                                } else {
                                    toastr.error(response.message && response.message != '' ? response.message : 'Hiba történt, kérjük frissítsd az oldalt és próbáld meg újra.');
                                    $('#saveResponseForm').prop('disabled', false).removeClass('disabled').find('i').addClass('d-none');
                                }
                                $('.loader').hide();
                                return;
                            },
                            error: function (response) {
                                $('.loader').hide();
                                toastr.error(response.message && response.message != '' ? response.message : 'Hiba történt, kérjük frissítsd az oldalt és próbáld meg újra.');
                                $('#saveResponseForm').prop('disabled', false).removeClass('disabled').find('i').addClass('d-none');
                            }
                        })
                    } else {
                        $('#saveResponseForm').prop('disabled', false).removeClass('disabled').find('i').addClass('d-none');
                        $('#responseModal').modal('show');
                    }
                }
            });
        })
    }

    if ($('#dataChangeModal').length > 0) {
        $('#saveDataChangeRequest').click(function (e) {
            e.preventDefault();

            var $form = $('#dataChangeForm');
            $('.loader').show();
            $.ajax({
                url: $form.attr('action'),
                method: 'POST',
                data: $form.serialize(),
                success: function (response) {
                    if (response.status == 'ok') {
                        $('#dataChangeModal').modal('hide');
                        toastr.success(response.message);
                    } else {
                        toastr.error(response.message && response.message != '' ? response.message : 'Hiba történt, kérjük frissítsd az oldalt és próbáld meg újra.');
                    }
                    $('.loader').hide();
                },
                error: function (response) {
                    if (response.message) {
                        toastr.error(response.message && response.message != '' ? response.message : 'Hiba történt, kérjük frissítsd az oldalt és próbáld meg újra.');
                    } else {
                        toastr.error('Hiba történt, kérjük próbálja újra.');
                    }
                    $('.loader').hide();
                }
            })
        });
    }

    if ($('#businessHoursForm').length) {
        if ($('#day_from').length) {
            $('#day_from').datepicker({
                language: 'hu',
                startDate: new Date(),
                endDate: '+2m',
                defaultDate: new Date()
            });
        }

        if ($('#day_to').length) {
            $('#day_to').datepicker({
                language: 'hu',
                startDate: '+1w',
                endDate: '+2m',
                defaultDate: '+2m'
            });
        }

        $('#businessHoursSubmit').click(function (e) {
            e.preventDefault();
            counter = 0;

            // show modal
            $('#timeoutModal').modal('show');

            formInterval = setInterval(function () {
                $('#saveTime').html((15 - counter));
                if (counter < 15) {
                    counter++;
                }
            }, 1000);


            formTimeout = setTimeout(function () {
                // clear interval and timeout on submit
                clearTimeout(formTimeout);
                clearInterval(formInterval);
                counter = 0;
                $('#businessHoursForm').submit();
            }, 5000);

            return false;
        });

        $('#cancelSave').click(function (e) {
            e.preventDefault();
            // clear timeout and interval
            if (formInterval != null) {
                clearInterval(formInterval);
            }
            if (formTimeout != null) {
                clearTimeout(formTimeout);
            }
            counter = 0;

            $('#saveTime').html('15');

            // hide modal
            $('#timeoutModal').modal('hide')
        });
    }
});
