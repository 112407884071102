$(document).ready(function () {
    if ($('#saveSearchBtn').length) {
        $('#saveSearchForm').keypress(function(e){
            var keycode = (e.keyCode ? e.keyCode : e.which);
            if(keycode == '13'){
                e.preventDefault();
                searchSave();
            }
        });

        $('#saveSearchBtn').click(function (e) {
            e.preventDefault();
            searchSave();
        });

        function searchSave(){
            var $form = $('#saveSearchForm');
            var url = $form.attr('action');

            $form.find('#search_url').val(window.location.href);

            $.ajax({
                url: url,
                method: 'POST',
                data: $form.serialize(),
                success: function (response) {
                    if (response.status === 'ok') {
                        toastr.success(response.message);
                    }
                    else {
                        toastr.error(response.message ? response.message : 'Hiba történt, kérjük próbálja újra.');
                    }

                    $('#searchSaveModal').modal('hide');
                },
                error: function (response) {
                    toastr.error('Hiba történt, kérjük próbálja újra.');
                    $('#searchSaveModal').modal('hide');
                }
            })
        }
    }

    if ($('.delete-search').length) {
        $('.delete-search').click(function (e) {
            e.preventDefault();

            var url = $(this).data('href');

            bootbox.confirm({
                title: 'Biztosan törlöd a kiválasztott keresést?',
                message: ' ',
                buttons: {
                    confirm: {
                        label: 'OK',
                    },
                    cancel: {
                        label: 'Mégse',
                    }
                },
                callback: function (r) {
                    if (r === true) {
                        window.location.replace(url);
                    }
                }
            });
        })
    }

    if ($('.template__search').length) {
        if (window.innerWidth <= 991) {
            $('.template__search .toggle-map').click();
        }

        $('.js-toggle-search').on('click', function (e) {
            $(this).toggleClass('active');
            if(typeof(window.store) !== 'undefined' ){
                window.store.dispatch({
                    type: 'SET_OPEN_DROPDOWN',
                    dropdown: null
                });
            }
            $('#app .property-search .filters-wrapper').slideToggle();
            $('#app .property-search .filterList').slideToggle('medium', function() {
                if ($(this).is(':visible')) {
                    $(this).css('display', 'flex');
                }
            });

        });
    }

});
