function initMap2() {

	var options = {
		center: new google.maps.LatLng(47.410741,19.006286),
		mapTypeControl: false,
		streetViewControl: true,
		zoomControl: false,
		fullscreenControl: true
	};

	var smallOptions = {
        center: new google.maps.LatLng(47.410741,19.006286),
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: false,
        fullscreenControl: false
	};

	if($('#g-map').length > 0) {

        if($('#g-map').data('latitude') && $('#g-map').data('longitude')){
            options['center'] = new google.maps.LatLng($('#g-map').data('latitude'),$('#g-map').data('longitude'));
        }

		var map = new google.maps.Map(document.getElementById('g-map'), options);
		map.setOptions({minZoom: 5, maxZoom: 19, zoom: 17});
		var marker = new google.maps.Marker({
			position: options.center,
			map: map,
			icon: {
				url: baseUrl+'/assets/img/svg/marker.svg',
				origin: new google.maps.Point(0, 0),
				scaledSize: new google.maps.Size(30, 35),
				labelOrigin: new google.maps.Point(75, 17)
			}
		});
	}

	if($('#g-map-small').length > 0) {

		if($('#g-map-small').data('latitude') && $('#g-map-small').data('longitude')){
			smallOptions['center'] = new google.maps.LatLng($('#g-map-small').data('latitude'),$('#g-map-small').data('longitude'));
		}

		var map_small = new google.maps.Map(document.getElementById('g-map-small'), smallOptions);
		map_small.setOptions({minZoom: 5, maxZoom: 19, zoom: 16});

		var marker_small = new google.maps.Marker({
			position: options.center,
			map: map_small,
			icon: {
				url: baseUrl+'/assets/img/svg/marker.svg',
				origin: new google.maps.Point(0, 0),
				scaledSize: new google.maps.Size(18, 21)
			}
		});

		$('#toBigMap').click(function(e){
			e.preventDefault();
			e.stopImmediatePropagation();

            $('html, body').animate({
				scrollTop: $('#g-map').offset().top - 110
			}, 1000);
		})
	}
}

$.filtermap = null;
var timer;

function initCompareMap() {

	var options = {
		center: new google.maps.LatLng(47.4811282,18.9902216),
		mapTypeControl: false,
		streetViewControl: false,
		zoomControl: false,
		fullscreenControl: false,
		minZoom: 5,
		maxZoom: 19,
		zoom: 10
	};

	if($('#g-map-compare').length > 0) {
		console.log('map found s');

		var map = new google.maps.Map(document.getElementById('g-map-compare'), options);
		var bounds = new google.maps.LatLngBounds();

		var locations = [];
		$('.marker').each(function(i, obj) {
			locations.push([$(obj).attr('data-latitude'), $(obj).attr('data-longitude')]);
		});

		if(locations.length) {
			for (var i = 0; i < locations.length; i++) {
				var marker = new google.maps.Marker({
					position: new google.maps.LatLng(locations[i][0], locations[i][1]),
					map: map,
					icon: {
						url: baseUrl + '/assets/img/svg/marker.svg',
						origin: new google.maps.Point(0, 0),
						scaledSize: new google.maps.Size(30, 35),
						labelOrigin: new google.maps.Point(75, 17)
					}
				});

				bounds.extend(marker.position);
			}
			map.fitBounds(bounds);
		}
        $.filtermap = map;

        return map;
	}

	if($('#g-map-lawyers').length > 0) {
		var map = new google.maps.Map(document.getElementById('g-map-lawyers'), options);
		var bounds = new google.maps.LatLngBounds();
		map.markers = [];

		var locations = [];
		$('.marker').each(function(i, obj) {
			locations.push([$(obj).attr('data-latitude'), $(obj).attr('data-longitude'), $(obj).attr('data-site-id')]);
		});

		if(locations.length) {
			for (i = 0; i < locations.length; i++) {
				var marker = new google.maps.Marker({
					position: new google.maps.LatLng(locations[i][0], locations[i][1]),
					map: map,
					icon: {
						path: "M10,23.4l10-10.8V0H0v12.6L10,23.4L10,23.4z",
						fillColor: '#008ccd',
						fillOpacity: 1,
						anchor: new google.maps.Point(20, 23),
						strokeWeight: 0,
						scale: 1
					},
					lawyerId: locations[i][2]
				});

				map.markers.push(marker);
				bounds.extend(marker.position);
			}
			map.fitBounds(bounds);
		}

        $.filtermap = map;
		return map;
	}
}


//Filtering markers
function filterMarkers(lawyerId) {

	for (var i = 0; i < $.filtermap.markers.length; i++) {
		var markertohide = $.filtermap.markers[i];

		if (markertohide.lawyerId == lawyerId) {
			var icon = markertohide.getIcon();
			icon.fillColor = '#008ccd';
			markertohide.setIcon(icon);
		}
		// LawyerId don't match
		else {
			var icon = markertohide.getIcon();
			icon.fillColor = '#959595';
			markertohide.setIcon(icon);
		}
	}
}

$(function() {
	// if($('#g-map').length > 0 || $('#g-map-small').length > 0) {
	// 	initMap();
	// }

	// if($('#g-map-compare').length > 0) {
	// 	$.filtermap = initCompareMap();
	// }

	$('.lawyer-option .lawyer-card').on('click', function () {
		$('.lawyer-option .lawyers-wrapper').addClass('active');
		$('.lawyer-option .lawyer-card').removeClass('active');
		$(this).addClass('active');
		$('.js-btn-next').removeClass('disabled');
		filterMarkers($(this).data('site-id'));
	});

	//Hovering on place images
	$('.lawyer-option .lawyer-card').hover(function() {
		// mouseenter
		var marker = $.filtermap.markers[$(this).index()];

		//Add some delay, for better user experience
		timer = setTimeout(function() {
			if (marker.getAnimation() != google.maps.Animation.BOUNCE) {
				marker.setAnimation(google.maps.Animation.BOUNCE);
			}
		}, 300);
	}, function() {
		// mouseleave
		clearTimeout(timer);
		var marker = $.filtermap.markers[$(this).index()];
		marker.setAnimation(null);
	});
});
