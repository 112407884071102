$(document).ready(function(){

    if ($('.add-to-wishlist').length || $('.add-to-compare').length) {
        $('.add-to-wishlist, .add-to-compare').click(function(e){
            var $this = $(this);
            e.preventDefault();
            var url = $this.data('href');

            $.ajax({
                url: url,
                method: 'POST',
                success: function(response){
                    if(response.status == 'ok'){
                        toastr.success(response.message);
                        $this.find('i').toggleClass('active');

                    }
                    else{
                        toastr.error(response.message);
                    }
                },
                error: function(response){
                    toastr.error('Hiba történt, kérjük próbálja újra.');
                }
            });
        });
    }

    if ($('.house-thumbnail.has-slick').length) {

        if ('ontouchstart' in window) {
            $('.house-thumbnail.has-slick').slick({
                arrows: true,
                dots: false
            });
        } else {
            $('.house-thumbnail.has-slick').mouseenter(function() {
                $(this).slick({
                    arrows: true,
                    dots: false
                });
            });
            $('.house-thumbnail.has-slick').mouseleave(function() {
                $(this).slick('unslick');
            });
        }
    }

    var rotate = 0;
    var upload_img_id = 0;
    $(document).on('click', '.upload-box .overlay, .upload-box .overlay svg', function(){
        rotate = 0;
        upload_img_id = $(this).parents('.upload-box').data('id');
        $('#rotate-image-modal img.img-container').css('transform', 'rotate(' + rotate + 'deg)');
        $('#rotate-image-modal img.img-container').attr('src', $(this).parents('.upload-box').data('original'));
        $('#rotate-image-modal').modal('show');
    });

    $('#rotate-image-modal .left').on('click', function(){
        rotate = rotate - 90;
        if(rotate < -270){
            rotate = 0;
        }

        $('#rotate-image-modal img.img-container').css('transform', 'rotate(' + rotate + 'deg)');
    });

    $('#rotate-image-modal .right').on('click', function(){
        rotate = rotate + 90;
        if(rotate > 270){
            rotate = 0;
        }

        $('#rotate-image-modal img.img-container').css('transform', 'rotate(' + rotate + 'deg)');
    });
    $('#rotate-image-modal .btn-primary').on('click', function(){
            $.ajax({
                url: $(this).data('url'),
                method: 'POST',
                data: {id: upload_img_id, rotate: rotate},
                success: function(response){
                    if(response.status == 'ok'){
                        $('#upload-image-' + response.id).css('background-image', "url('" + response.file + "')");
                        $('#rotate-image-modal').modal('hide');
                    }
                    else{
                        toastr.error(response.message);
                    }
                },
                error: function(response){
                    toastr.error('Hiba történt, kérjük próbálja újra.');
                }
            });
    });

});
