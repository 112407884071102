function initMap() {

	var options = {
		center: new google.maps.LatLng(47.410741,19.006286),
		mapTypeControl: false,
		streetViewControl: false,
		zoomControl: false,
		fullscreenControl: false
	};

	if($('#g-map').length > 0) {

        if($('#g-map').data('latitude') && $('#g-map').data('longitude')){
            options['center'] = new google.maps.LatLng($('#g-map').data('latitude'),$('#g-map').data('longitude'));
        }

		var map = new google.maps.Map(document.getElementById('g-map'), options);
		map.setOptions({minZoom: 5, maxZoom: 19, zoom: 17});
		var marker = new google.maps.Marker({
			position: options.center,
			map: map,
			icon: {
				url: baseUrl+'/assets/img/svg/marker.svg',
				origin: new google.maps.Point(0, 0),
				scaledSize: new google.maps.Size(30, 35),
				labelOrigin: new google.maps.Point(75, 17)
			}
		});
	}

	if($('#g-map-small').length > 0) {

		if($('#g-map-small').data('latitude') && $('#g-map-small').data('longitude')){
			options['center'] = new google.maps.LatLng($('#g-map-small').data('latitude'),$('#g-map-small').data('longitude'));
		}

		var map_small = new google.maps.Map(document.getElementById('g-map-small'), options);
		map_small.setOptions({minZoom: 5, maxZoom: 19, zoom: 16});

		var marker_small = new google.maps.Marker({
			position: options.center,
			map: map_small,
			icon: {
				url: baseUrl+'/assets/img/svg/marker.svg',
				origin: new google.maps.Point(0, 0),
				scaledSize: new google.maps.Size(18, 21)
			}
		});
	}
}

(function($) {
	"use strict";
	$(function() {
		if($('#g-map').length > 0 || $('#g-map-small').length > 0) {
			initMap();
		}
	});

})(jQuery);