function readURL(input, id) {

    if (input.files && input.files[0]) {
        var reader = new FileReader();

        var file = input.files[0];

        if (!file.type.match('image.*')) {
            bootbox.alert('Csak kép tölthető fel!');
            return;
        }

        if (file.size > 5242880) {
            bootbox.alert('Maximum 5MB méretű fájl tölthető fel.');
            return;
        }

        reader.onload = function(e) {
            $(id).attr('src', e.target.result);
        };

        reader.readAsDataURL(input.files[0]);
    }
}

$(document).ready(function(){

    if ($('#userProfileForm').length) {
        $('#phone').inputmask({"mask": "+99 9{1,2} 999 9999"});
	}

    $('#userProfileForm input').on('change, keyup', function(e){
        var $this = $(this);
        if($this.hasClass('is-invalid')){
            $this.removeClass('is-invalid');
        }

        if($this.closest('.form-group').hasClass('error')){
            $this.closest('.form-group').removeClass('error');
        }
    });

    $('#userProfileForm').find('#birthdate').datepicker({
        language: 'hu',
        startView: 'months',
        endDate: $('#birthdate').data('end')
    });

    $('#userProfilePicTrigger').click(function(e){
        e.preventDefault();
        $('#userProfilePic').click();
    });

    $("#userProfilePic").change(function() {
        readURL(this, '#userImage');
    });


    $('#userProfileForm').submit(function(e){
        var $form = $('#userProfileForm');

        var formData = new FormData($form[0]);

        e.preventDefault();
        loader(true, '#userProfileForm button[type="submit"]');
        toastr.clear();
        $.ajax({
            url: $form.attr('action'),
            method: 'POST',
            data: formData,
            enctype: 'multipart/form-data',
            processData: false,  // Important!
            contentType: false,
            cache: false,
            success: function(response){
                loader(false, '#userProfileForm button[type="submit"]');
                if(response.status == 'ok'){
                    toastr.success('Sikeres mentés!');
                }
                else{
                    if(response.field){
                        $('#'+response.field).addClass('is-invalid');
                    }
                    toastr.error(response.message && response.message != '' ? response.message : 'Hiba történt, kérjük frissítsd az oldalt és próbáld meg újra.');
                }
            },
            error: function(response){
                loader(false, '#userProfileForm button[type="submit"]');
                if(response.status === 422){
                    var response = response.responseJSON;
                    for(var k in response.errors){
                        $('#'+k).closest('.form-group').addClass('error').find('p.error-response').html(response.errors[k][0]);
                    }

                    if ($('.form-group.error').length > 0) {
                        var first_position = $('.form-group.error').first().offset().top;
                        $('html, body').animate({
                            scrollTop: first_position - 200
                        }, 500);
                    }

                    return;
                }

                if(response.message){
                    toastr.error(response.message && response.message != '' ? response.message : 'Hiba történt, kérjük frissítsd az oldalt és próbáld meg újra.');
                }
                else{
                    toastr.error('Hiba történt, kérjük próbálja újra');
                }
            }
        });

        return false;
    });

    if($('#deactivateProfile').length){
        $('#deactivateProfile').click(function(e){
            e.preventDefault();

            var $this = $(this);

            bootbox.confirm({
                title: 'Biztosan deaktiválod a fiókodat?',
                message: ' ',
                buttons: {
                    confirm: {
                        label: 'OK',
                    },
                    cancel: {
                        label: 'Mégse',
                    }
                },
                callback: function(r){
                    if(r === true){
                        window.location.replace($this.data('href'));
                    }else{
                        loader(false, '#deactivateProfile');
                    }
                }
            });
        })
    }
});