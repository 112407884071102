$(document).ready(function () {

    if ($('#reg_password').length) {
        $('#reg_password').password({
            shortPass: 'A jelszó túl rövid',
            badPass: 'Gyenge jelszó, kérjük adj meg számokat és betűket',
            goodPass: 'Közepes, próbálj meg speciális karaktereket megadni',
            strongPass: 'Erős jelszó',
            containsField: 'A jelszó tartalmazza a felhasználónevet',
            enterPass: 'Írd be a jelszót',
            showPercent: false,
            showText: true, // shows the text tips
            animate: true, // whether or not to animate the progress bar on input blur/focus
            animateSpeed: 'fast', // the above animation speed
            field: false, // select the match field (selector or jQuery instance) for better password checks
            fieldPartialMatch: true, // whether to check for partials in field
            minimumLength: 6 // minimum password length (below this threshold, the score is 0)
        });
    }

    if ($('#passreset').length) {
        $('#password').password({
            shortPass: 'A jelszó túl rövid',
            badPass: 'Gyenge jelszó, kérjük adj meg számokat és betűket',
            goodPass: 'Közepes, próbálj meg speciális karaktereket megadni',
            strongPass: 'Erős jelszó',
            containsField: 'A jelszó tartalmazza a felhasználónevet',
            enterPass: 'Írd be a jelszót',
            showPercent: false,
            showText: true, // shows the text tips
            animate: true, // whether or not to animate the progress bar on input blur/focus
            animateSpeed: 'fast', // the above animation speed
            field: false, // select the match field (selector or jQuery instance) for better password checks
            fieldPartialMatch: true, // whether to check for partials in field
            minimumLength: 6 // minimum password length (below this threshold, the score is 0)
        });
    }

    //if enter button is pressed in login input fields -> login
    $('#loginModal input').each(function (i, obj) {
        (obj).addEventListener("keyup", function (event) {
            event.preventDefault();
            if (event.keyCode === 13) {
                $('#loginBtn').click();
            }
        });
    });

    $('#loginBtn').click(function () {
        var $form = $('#ajaxLoginForm');
        var redirectTo = $form.find('#redirectTo').val().trim();
        loader(true, '#loginBtn');
        toastr.clear();
        $.ajax({
            url: $form.attr('action'),
            method: 'POST',
            data: $form.serialize(),
            success: function (response) {
                loader(false, '#loginBtn');
                if (response.status == 'ok') {
                    if (redirectTo != '') {
                        window.location.replace(redirectTo);
                    }
                    else {
                        window.location.reload();
                    }
                }
                else {
                    toastr.options.positionClass = 'toast-top-center';
                    toastr.error(response.message && response.message != '' ? response.message : 'Hiba történt, kérjük frissítsd az oldalt és próbáld meg újra.');
                    toastr.options.positionClass = 'toast-bottom-right';
                }

            },
            error: function (response) {
                loader(false, '#loginBtn');
                if (response.message) {
                    toastr.options.positionClass = 'toast-top-center';
                    toastr.error(response.message && response.message != '' ? response.message : 'Hiba történt, kérjük frissítsd az oldalt és próbáld meg újra.');
                    toastr.options.positionClass = 'toast-bottom-right';
                }
                else {
                    toastr.options.positionClass = 'toast-top-center';
                    toastr.error('Hiba történt, kérjük próbálja újra');
                    toastr.options.positionClass = 'toast-bottom-right';
                }

            }
        })
    });

    $('#regBtn').click(function (e) {
        var $form = $('#ajaxRegForm');
        e.preventDefault();
        loader(true, '#regBtn');
        toastr.clear();
        $.ajax({
            url: $form.attr('action'),
            type: 'POST',
            data: $form.serialize(),
            dataType: 'json',
            success: function (response) {
                loader(false, '#regBtn');
                if (response.status == 'ok') {
                    toastr.options.positionClass = 'toast-top-center';
                    toastr.success(response.message);
                    toastr.options.positionClass = 'toast-bottom-right';
                    $('#regModal').modal('hide');
                }
                else {
                    if (response.errors) {

                        for (var k in response.errors) {
                            $('#' + k).parent().addClass('error');
                        }
                    }
                    else {
                        for (var k in response.message) {
                            $('#' + k).parent().addClass('error');
                        }
                    }

                    if ($('.form-group.error').length > 0) {
                        var first_position = $('.form-group.error').first().offset().top;
                        $('html, body').animate({
                            scrollTop: first_position - 100
                        }, 500);
                    }

                    // toastr.options.positionClass = 'toast-top-center';
                    // toastr.error(response.message && response.message != '' ? response.message : 'Hiba történt, kérjük frissítsd az oldalt és próbáld meg újra.');
                    // toastr.options.positionClass = 'toast-bottom-right';
                }
            },
            error: function (response) {
                loader(false, '#regBtn');
                if (response.status == 422) {
                    var response = response.responseJSON;
                    for (var k in response.errors) {
                        $('#' + k).closest('.form-group').addClass('error').find('p.error-response').html(response.errors[k][0]);
                    }

                    if ($('.form-group.error').length > 0) {
                        var first_position = $('.form-group.error').first().offset().top;
                        $('html, body').animate({
                            scrollTop: first_position - 100
                        }, 500);
                    }

                    return;
                }

                if (response.message) {
                    toastr.options.positionClass = 'toast-top-center';
                    toastr.error(response.message && response.message != '' ? response.message : 'Hiba történt, kérjük frissítsd az oldalt és próbáld meg újra.');
                    toastr.options.positionClass = 'toast-bottom-right';
                }
                else {
                    toastr.options.positionClass = 'toast-top-center';
                    toastr.error('Hiba történt, kérjük próbálja újra');
                    toastr.options.positionClass = 'toast-bottom-right';
                }
            }
        })
    });

    $('#ajaxRegForm input').on('keyup change', function () {
        var $this = $(this);
        if ($this.closest('.form-group').hasClass('error') && $this.val().trim() != '') {
            $this.closest('.form-group').removeClass('error');
        }
    })
});

$(document).on('click', '#requestNewVerification', function (e) {
    var id = $(this).data('id');
    loader(true, '#requestNewVerification');
    $.ajax({
        url: '/verification/new',
        type: 'POST',
        data: {
            user_id: id
        },
        success: function (response) {
            loader(false, '#requestNewVerification');
            if (response.status == 'ok') {
                toastr.options.positionClass = 'toast-top-center';
                toastr.success(response.message);
                toastr.options.positionClass = 'toast-bottom-right';
            }
            else {
                toastr.options.positionClass = 'toast-top-center';
                toastr.error(response.message && response.message != '' ? response.message : 'Hiba történt, kérjük frissítsd az oldalt és próbáld meg újra.');
                toastr.options.positionClass = 'toast-bottom-right';
            }
        },
        error: function (response) {
            loader(false, '#requestNewVerification');
            if (response.message) {
                toastr.options.positionClass = 'toast-top-center';
                toastr.error(response.message && response.message != '' ? response.message : 'Hiba történt, kérjük frissítsd az oldalt és próbáld meg újra.');
                toastr.options.positionClass = 'toast-bottom-right';
            }
            else {
                toastr.options.positionClass = 'toast-top-center';
                toastr.error('Hiba történt, kérjük próbálja újra');
                toastr.options.positionClass = 'toast-bottom-right';
            }
        }
    });
});

$(document).on('click', '#requestNewPassword', function (e) {
    e.preventDefault();
    var id = $(this).data('id');

    loader(true, '#requestNewPassword');
    $.ajax({
        url: '/verification/new',
        type: 'POST',
        data: {
            user_id: id,
            activation: 1
        },
        success: function (response) {
            loader(false, '#requestNewPassword');
            if (response.status == 'ok') {
                toastr.options.positionClass = 'toast-top-center';
                toastr.success(response.message);
                toastr.options.positionClass = 'toast-bottom-right';
            }
            else {
                toastr.options.positionClass = 'toast-top-center';
                toastr.error(response.message && response.message != '' ? response.message : 'Hiba történt, kérjük frissítsd az oldalt és próbáld meg újra.');
                toastr.options.positionClass = 'toast-bottom-right';
            }
        },
        error: function (response) {
            loader(false, '#requestNewPassword');
            if (response.message) {
                toastr.options.positionClass = 'toast-top-center';
                toastr.error(response.message && response.message != '' ? response.message : 'Hiba történt, kérjük frissítsd az oldalt és próbáld meg újra.');
                toastr.options.positionClass = 'toast-bottom-right';
            }
            else {
                toastr.options.positionClass = 'toast-top-center';
                toastr.error('Hiba történt, kérjük próbálja újra');
                toastr.options.positionClass = 'toast-bottom-right';
            }
        }
    });
});
