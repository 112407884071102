function handlePropertyType($elem, $container){
    if($elem.find('option:selected').length){
        if($elem.find('option:selected').data('is_special') == 1){
            $container.find('#floor_type')[0].sumo.unSelectAll();
            $container.find('#building, #floor, #door').val('').prop('readonly', true);
            $container.find('#floor_type')[0].sumo.disable();
            // $container.find('#floor_type')[0].sumo.;
            $container.find('#rooms, #hrooms').val(0).prop('readonly', true);
            $container.find('#rooms, #hrooms').parents('div.form-group').find('label span.required').addClass('d-none');
        }
        else{
            $container.find('#building, #floor, #floor_type, #door').prop('readonly', false);
            $container.find('#floor_type')[0].sumo.enable();
            $container.find('#rooms, #hrooms').prop('readonly', false);
            $container.find('#rooms, #hrooms').parents('div.form-group').find('label span.required').removeClass('d-none');
        }
    }
}

$(document).ready(function(){
    if($('#price').length){
        $('#price').maskMoney({thousands:' ', allowZero:true, precision: 0, allowNegative: false, allowEmpty: true});
        $('#price').maskMoney('mask');

        if($('#hiddenPrice').length){
            $('#price').keyup(function(e){
                $('#hiddenPrice').val($('#price').val().split(' ').join(''));
            })
        }
    }

    if($('.property-edit').length){

        $('.property-edit').find('select#property_type').change(function(){
            handlePropertyType($('.property-edit').find('select#property_type'), $('.property-edit'));
        });

        handlePropertyType($('.property-edit').find('select#property_type'), $('.property-edit'));

        $('.property-edit').find('input, select, checkbox').on('change focusin click', function(){
            $(this).parents('.form-group').removeClass('error');
        });

        $('.property-edit').find('input#rooms, input#hrooms').on('keyup', function(){
            if($(this).val().search(/^[0-9]+$/) < 0){
                $(this).val($(this).val().substr(0, $(this).val().length - 1));
            }
        });
    }

    if($('.property-create').length){

        $('.property-create').find('select#property_type').change(function(){
            handlePropertyType($('.property-create').find('select#property_type'), $('.property-create'));
        });

        handlePropertyType($('.property-create').find('select#property_type'), $('.property-create'));

        $('.property-create').find('input, select, checkbox').on('change focusin click', function(){
            $(this).parents('.form-group').removeClass('error');
        });

        $('.property-create').find('input#rooms, input#hrooms').on('keyup', function(){
            if($(this).val().search(/^[0-9]+$/) < 0){
                $(this).val($(this).val().substr(0, $(this).val().length - 1));
            }
        });
    }

    if($('#price_eur').length){
        $('#price_eur').maskMoney({thousands:' ', allowZero:true, precision: 0, allowNegative: false}).blur();
		$('#price_eur').maskMoney('mask');

        if($('#hiddenPriceEur').length){
            $('#price_eur').keyup(function(e){
                $('#hiddenPriceEur').val($('#price_eur').val().split(' ').join(''));
            })
        }
    }

    if($('#price_usd').length){
        $('#price_usd').maskMoney({thousands:' ', allowZero:true, precision: 0, allowNegative: false}).blur();
		$('#price_usd').maskMoney('mask');

        if($('#hiddenPriceUsd').length){
            $('#price_usd').keyup(function(e){
                $('#hiddenPriceUsd').val($('#price_usd').val().split(' ').join(''));
            })
        }
    }

    if($('#meta_uzemeltetesi_koltseg').length){
        $('#meta_uzemeltetesi_koltseg').maskMoney({thousands:' ', allowZero:true, precision: 0, allowNegative: false}).blur();
        $('#meta_uzemeltetesi_koltseg').maskMoney('mask');

        if($('#hidden_uzemeltetesi_koltseg').length){
            $('#meta_uzemeltetesi_koltseg').keyup(function(e){
                $('#hidden_uzemeltetesi_koltseg').val($('#meta_uzemeltetesi_koltseg').val().split(' ').join(''));
            })
        }
    }

    if($('#meta_kozoskoltseg').length){
        $('#meta_kozoskoltseg').maskMoney({thousands:' ', allowZero:true, precision: 0, allowNegative: false}).blur();
        $('#meta_kozoskoltseg').maskMoney('mask');

        if($('#hidden_kozoskoltseg').length){
            $('#meta_kozoskoltseg').keyup(function(e){
                $('#hidden_kozoskoltseg').val($('#meta_kozoskoltseg').val().split(' ').join(''));
            })
        }
    }

    if($('#owner_birthdate').length){
        $('#owner_birthdate').datepicker({
            language: 'hu'
        });
    }

    if($('.charlimited').length){
        $('.charlimited').change();
    }

    if ($('.add-to-wishlist').length || $('.add-to-compare').length) {
        $('.add-to-wishlist, .add-to-compare, .add-to-hide').click(function(e){
            var $this = $(this);
            e.preventDefault();
            var url = $this.data('href');
            var id = $this.data('id');

            $.ajax({
                url: url,
                method: 'POST',
                data: {
                    property_id: id
                },
                success: function(response){
                    if(response.status == 'ok'){
                        if($this.hasClass('remove-prop-from-compare')){
                            window.location.reload();
                            return;
                        }
                        toastr.success(response.message);
                        $this.find('i').toggleClass('active');

                    }
                    else{
                        toastr.error(response.message && response.message != '' ? response.message : 'Hiba történt, kérjük frissítsd az oldalt és próbáld meg újra.');
                    }
                },
                error: function(response){
                    toastr.error('Hiba történt, kérjük próbálja újra.');
                }
            });
        });
    }

    if ($('.house-thumbnail.has-slick').length) {

        if ('ontouchstart' in window) {
            $('.house-thumbnail.has-slick').slick({
                arrows: true,
                dots: false
            });
        } else {
            $('.house-thumbnail.has-slick').mouseenter(function() {
                $(this).slick({
                    arrows: true,
                    dots: false
                });
            });
            $('.house-thumbnail.has-slick').mouseleave(function() {
                $(this).slick('unslick');
            });
        }
    }

    if($('.property-edit').find('.nav-pills').length){
        var $is_first = false;
        $('.property-edit').find('.nav-pills .nav-link').each(function(){
            var $this = $(this);
            var tabId = $this.attr('href');
            if($(tabId).find('.form-group.error').length){
                if($is_first == false){
                    $is_first = $this;
                }
                $this.addClass('error');
            }
        });

        if($is_first){
            $is_first.click();
        }
    }

    if($('.userDeleteProperty').length){
        $('.userDeleteProperty').click(function(e){
            e.preventDefault();

            var $this = $(this);

            bootbox.confirm({
                title: 'Biztosan törlöd az ingatlant?',
                message: ' ',
                buttons: {
                    confirm: {
                        label: 'OK',
                    },
                    cancel: {
                        label: 'Mégse',
                    }
                },
                callback: function(r){
                    if(r === true){
                        $this.parent().submit();
                    }
                }
            });
        })
    }

    if ($('.property-show .daily-statistics').length > 0) {
		google.charts.load('current', {'packages':['corechart']});
		google.charts.setOnLoadCallback(drawChart);

		var datas = [['Nap', 'Napi oldalbetöltés']];
		$('#daily-statistics div').each(function(i, obj) {
			datas.push([parseInt($(obj).attr('data-day')), parseInt($(obj).attr('data-visitors'))]);
		});

		function drawChart() {
			var data = google.visualization.arrayToDataTable(datas);

			var options = {
				chartArea: {
				    left:100,
                    right: 0,
                    height: 250,
                    top: 20,
                    bottom: 20
                },
				curveType: 'function',
				legend: {
					position: 'none'
				},
				hAxis: {
					gridlines: {
						color: 'transparent'
					},
					textStyle: {
						fontName: 'Montserrat',
						fontSize: 16,
						italic: false
					},
                    format: '0'
				},
				series: {
					0: {
						color: '#008ccd'
					}
				},
				vAxis: {
					textStyle: {
						fontName: 'Montserrat',
						fontSize: 16,
						italic: false
					},
					title: 'Napi oldalbetöltés',
					titleTextStyle: {
						fontName: 'Montserrat',
						fontSize: 16,
						italic: false
					},
					viewWindow: {
						min: 0
					}
				}
			};

			var chart = new google.visualization.LineChart(document.getElementById('daily-statistics'));

			chart.draw(data, options);
		}

		//create trigger to resizeEnd event
		$(window).resize(function() {
			if(this.resizeTO) clearTimeout(this.resizeTO);
			this.resizeTO = setTimeout(function() {
				$(this).trigger('resizeEnd');
			}, 500);
		});

		//redraw graph when window resize is completed
		$(window).on('resizeEnd', function() {
			drawChart();
		});
    }

    /**
     * Handle special fields
     */
    if($('.add-new-room').length){
        $('.add-new-room').click(function(e){
            e.preventDefault();
            var $lastRoom = $('.rooms-repeat-container').find('.rooms-repeat');

            var repeatNum = parseInt($lastRoom.attr('data-repeat')) + 1;

            var $clonedElement = $lastRoom.clone();

            $clonedElement.removeClass('d-none').removeClass('rooms-repeat');

            var $replaced = replaceAll($clonedElement.prop('outerHTML').toString(), '%s', repeatNum);

            $replaced = $($replaced);
            $replaced.find('select').SumoSelect({
                placeholder: 'Nincs megadva',
                forceCustomRendering: true,
                csvDispCount: 1,
                captionFormat: '{0} elem kiválasztva',
                captionFormatAllSelected: 'Összes kiválasztva',
                searchText: 'Keresés',
                locale: ['Rendben', 'Mégse', 'Összes kiválasztása']
            });

            $('.rooms-repeat').attr('data-repeat', repeatNum.toString());

            $('.rooms-repeat-container').append($replaced);
        })
    }

    if($('.add-new-felujitasok').length){
        $('.add-new-felujitasok').click(function(e){
            e.preventDefault();

            var $lastRoom = $('.felujitasok-repeat-container').find('.felujitasok-repeat');

            var repeatNum = parseInt($lastRoom.attr('data-repeat')) + 1;

            var $clonedElement = $lastRoom.clone();

            $clonedElement.removeClass('d-none').removeClass('felujitasok-repeat');

            var $replaced = replaceAll($clonedElement.prop('outerHTML').toString(), '%s', repeatNum);

            $replaced = $($replaced);

            $('.felujitasok-repeat').attr('data-repeat', repeatNum.toString());

            $('.felujitasok-repeat-container').append($replaced);
        })
    }

    if($('.property-edit').length){
        $('.datepicker').datepicker({
            language: 'hu',
            startDate: new Date()
        });
    };

    if($('.property-block').length) {
        $('.property-block').click(function (e) {
            e.preventDefault();

            var url = $(this).data('href');

            bootbox.prompt({
                title: 'Kérjük, add meg a jelentés okát:',
                inputType: 'textarea',
                closeButton: false,
                buttons: {
                    confirm: {
                        'label': 'Ingatlan jelentése'
                    },
                    cancel: {
                        'label': 'Mégsem'
                    }
                },
                callback: function (r) {
                    if(r === null){
                        return true;
                    }
                    if (r !== '') {
                        $.ajax({
                            url: url,
                            method: 'POST',
                            data: {
                                reason: r
                            },
                            success: function (response) {
                                if (response.status == 'ok') {
                                    toastr.success(response.message);

                                    $('.property-block').hide();
                                }
                                else {
                                    toastr.error(response.message && response.message != '' ? response.message : 'Hiba történt, kérjük frissítsd az oldalt és próbáld meg újra.');
                                }
                            },
                            error: function (response) {
                                toastr.error('Hiba történt, kérjük próbálja újra.');
                            }
                        })
                    }
                    else{
                        toastr.error('A jelentés okának megadása kötelező!');
                    }
                }
            })
        })
    }

});

$(document).on('click', '.remove-room-btn', function(e){
    e.preventDefault();
    if($('.remove-room-btn').length > 2){
        $(this).parent().parent().remove();
    }
});

$(document).on('click', '.remove-felujitasok-btn', function(e){
    e.preventDefault();
    if($('.remove-felujitasok-btn').length > 2){
        $(this).parent().parent().remove();
    }
});