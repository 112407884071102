function initSortable() {
	if ($('#uploaded-files').hasClass('sort-enabled')) {
		$('#uploaded-files.sort-enabled').sortable({
			items: '.has-img',
			stop: function (event, ui) {
				var photos_order = [];

				$('#uploaded-files.sort-enabled').find('.has-img').each(function () {
					photos_order.push($(this).data('id'));
				})

				$.ajax({
					url: '/uploads/reorder',
					method: 'POST',
					data: {
						photos_order: photos_order
					},
					success: function (response) {
						console.log(response);
					},
					error: function (response) {
						console.log(response);
					}
				})
			}
		});
		$('#uploaded-files.sort-enabled').disableSelection();
	}
}

(function ($) {
	"use strict";
	$(function () {

		toastr.options = {
			"closeButton": true,
			"debug": false,
			"newestOnTop": false,
			"progressBar": false,
			"positionClass": "toast-bottom-right",
			"preventDuplicates": false,
			"onclick": null,
			"showDuration": "300",
			"hideDuration": "0",
			"timeOut": "10000",
			"extendedTimeOut": "0",
			"showEasing": "swing",
			"hideEasing": "linear",
			"showMethod": "fadeIn",
			"hideMethod": "fadeOut",
			"closeHtml": '<button type="button"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 360" width="25" height="25"><circle stroke-width="18" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="180" cy="180" r="171"/><line stroke-width="18" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="117" y1="243" x2="243" y2="117"/><line stroke-width="18" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="117" y1="117" x2="243" y2="243"/></svg></button>'
		};

		if ($('.nav-link.has-tooltip').length) {
			$('.nav-link.has-tooltip').tooltip({
				container: 'body'
			});
		}

		if ($('.nav-item.has-tooltip').length) {
			$('.nav-item.has-tooltip').tooltip({
				container: 'body'
			});
		}


		$('.deals-dropdown').on('show.bs.dropdown', function () {
			$('.deals-dropdown.has-tooltip').tooltip('hide')
			$('.deals-dropdown.has-tooltip').tooltip('disable')
		})

		$('.deals-dropdown').on('hide.bs.dropdown', function () {
			$('.deals-dropdown.has-tooltip').tooltip('enable')
		})



		if ($('.to-page-top').length) {
			$('.to-page-top').click(function (e) {
				e.preventDefault();

				$('html, body').animate({ scrollTop: 0 }, 1000);
			})
		}

		objectFitImages();

		//Add Sumoselect for select fields
		$('.js-custom-select').SumoSelect({
			placeholder: 'Nincs megadva',
			forceCustomRendering: true,
			csvDispCount: 1,
			captionFormat: '{0} elem kiválasztva',
			captionFormatAllSelected: 'Összes kiválasztva',
			searchText: 'Keresés',
			locale: ['Rendben', 'Mégse', 'Összes kiválasztása']
        });

        $('.js-custom-select').each(function(){
            if($(this).val() == '' || $(this).val() == 0){
                $(this).parents('.SumoSelect').find('.CaptionCont > span').css('color', '#959595');
            }else{
                $(this).parent('.SumoSelect').find('.CaptionCont > span').css('color', '#000');
            }
        });

        $('.js-custom-select').on('change', function(){
            if($(this).val() == '' || $(this).val() == 0){
                $(this).parents('.SumoSelect').find('.CaptionCont > span').css('color', '#959595');
            }else{
                $(this).parent('.SumoSelect').find('.CaptionCont > span').css('color', '#000');
            }
        });

		if (window.localStorage) {
			if (!window.localStorage.getItem('cookie_notice_accepted')) {
				$('.cookie-popup').removeClass('d-none');
			}
		}

		$('#acceptCookies').click(function (e) {
			e.preventDefault();
			if (window.localStorage) {
				window.localStorage.setItem('cookie_notice_accepted', 1);
				$('.cookie-popup').addClass('d-none');
			}
		});

        /**
		 * Homepage search submit
         */
		$('#mainSearchFormSubmit, #mobileSearchFormSubmit').click(function (e) {
			e.preventDefault();

			var prefix = '';
			if ($(this).attr('id') == 'mainSearchFormSubmit') {
				var $searchForm = $('#mainSearchForm');
			}
			else {
				var $searchForm = $('#mobileSearchForm');
				prefix = 'mobil_';
			}


			var searchObj = {};
			var property_types = [];

			if ($searchForm.find('#' + prefix + 'property_type option:selected').hasClass("option-parent")) {
				var parent_id = $searchForm.find('#' + prefix + 'property_type').val();
				$searchForm.find('#' + prefix + 'property_type option[data-parent-id="' + parent_id + '"]').each(function () {
                    if(parseInt($(this).attr('value')) != ''){
                        property_types.push(parseInt($(this).attr('value')));
                    }
                });
                if(parseInt(parent_id) != ''){
                    property_types.push(parseInt(parent_id));
                }
			} else {
                if($searchForm.find('#' + prefix + 'property_type').val() != ''){
                    property_types.push($searchForm.find('#' + prefix + 'property_type').val());
                }
			}


			searchObj['q'] = $searchForm.find('#' + prefix + 'address').val() != '' ? $searchForm.find('#' + prefix + 'address').val() : null;
			searchObj['property_types'] = property_types;
			searchObj['price'] = {};
			searchObj['rooms'] = {};
			searchObj['floorspace'] = {};
			searchObj['price']['from'] = $searchForm.find('#' + prefix + 'price_from').val() != '' ? parseInt($searchForm.find('#' + prefix + 'price_from').val() * 1000000) : null;
			searchObj['price']['to'] = $searchForm.find('#' + prefix + 'price_to').val() != '' ? parseInt($searchForm.find('#' + prefix + 'price_to').val() * 1000000) : null;
			searchObj['floorspace']['from'] = $searchForm.find('#' + prefix + 'floorspace_from').val() != '' ? $searchForm.find('#' + prefix + 'floorspace_from').val() : null;
			searchObj['floorspace']['to'] = $searchForm.find('#' + prefix + 'floorspace_to').val() != '' ? $searchForm.find('#' + prefix + 'floorspace_to').val() : null;
			searchObj['rooms']['from'] = $searchForm.find('#' + prefix + 'rooms').val() != '' ? $searchForm.find('#' + prefix + 'rooms').val() : null;

			$searchForm.attr('action', '/search?f=' + JSON.stringify(searchObj));

			window.location.href = '/search?f=' + JSON.stringify(searchObj);
			return;
		});

		//add clear buttons with functionality for input fields
		$('input.form-control:not([readonly]):not(.nojs)').wrap('<span class="input-wrapper"/>').after($('<span class="clear-button"/>').click(function () {
			$(this).prev('input').val('').trigger('propertychange').focus();
		}));

		$('input.form-control:not([readonly]):not(.nojs)').on('input propertychange', function () {
			var $this = $(this);
			var visible = Boolean($this.val());
			$this.siblings('.clear-button').toggleClass('d-none', !visible);
		}).trigger('propertychange');

		$('.range-box:not(.no-js)').append($('<span class="clear-button"/>').click(function () {
			$(this).parent().find('input').val('').trigger('propertychange');
			$(this).parent().removeClass('focus');
		}));

		$('.range-box:not(.no-js) input').on('input propertychange', function () {
			var $this = $(this);
			var visible = Boolean($this.val() + $this.siblings('input').val());
			$this.siblings('.clear-button').toggleClass('d-none', !visible);
		}).trigger('propertychange');

		$('.js-custom-select:not(.no-js)').each(function (i, obj) {
			var $this = $(this);

			//multiple select clear button add on document load if necessary
			if (obj.hasAttribute('multiple')) {
				if (obj.selectedOptions.length !== 0) {
					$(this).parent().parent().addClass('has-clear');
					$(this).parent().append($('<span class="clear-button"/>').click(function () {
						$(this).parent().find('.js-custom-select')[0].sumo.unSelectAll();
					}));
				}
				//single select clear button add on document load if necessary
			} else {
				if (obj.selectedIndex !== 0) {
					$(this).parent().parent().addClass('has-clear');
					$(this).parent().append($('<span class="clear-button"/>').click(function () {
						$(this).parent().find('.js-custom-select')[0].sumo.unSelectAll();
					}));
				}
			}
		});

		//no-input clicked -> remove previously selected option
		$('.form-group .no-input').change(function () {
			var $this = $(this);
			if ($this.prop('checked')) {

				//multiple select
				if ($this.siblings('.SumoSelect').children('.js-custom-select')[0].hasAttribute('multiple')) {
					$this.siblings('.SumoSelect').children('.js-custom-select')[0].sumo.unSelectAll();
					//single select
				} else {
					//has placeholder -> select that
					if ($this.siblings('.SumoSelect').find('.js-custom-select option:first')[0].hasAttribute('disabled')) {
						$this.siblings('.SumoSelect').children('.js-custom-select')[0].sumo.enableItem(0);
						$this.siblings('.SumoSelect').children('.js-custom-select')[0].sumo.unSelectAll();
						$this.siblings('.SumoSelect').children('.js-custom-select')[0].sumo.selectItem(0);
						$this.siblings('.SumoSelect').find('.CaptionCont > span').addClass('placeholder');
						$this.siblings('.SumoSelect').find('.optWrapper .options .opt').eq(0).addClass('disabled');
						//no placeholder -> select first item and add placeholder
					} else {
						$this.siblings('.SumoSelect').children('.js-custom-select')[0].sumo.selectItem(0);
						$this.siblings('.SumoSelect').find('.optWrapper .options .opt').removeClass('selected');
						$this.siblings('.SumoSelect').find('.CaptionCont > span').addClass('placeholder');
					}
				}
			}
		});

		//no-input and clear button funtionality on select change
		$(".custom-select:not(.no-js)").change(function (e) {
			var $this = $(this);

			if ($this.parent().siblings('.no-input').length) {
				if ($this.parent().siblings('.no-input')[0].checked === true) {
					$this.parent().siblings('.no-input').click();
				}
			}

			if (e.target.hasAttribute('multiple')) {
				if (e.target.selectedOptions.length === 0) {
					if ($this.parent().parent().hasClass('has-clear')) {
						$this.parent().parent().removeClass('has-clear');
						$this.siblings('.clear-button')[0].remove();
					}
				} else {
					if (!$this.parent().parent().hasClass('has-clear')) {
						$this.parent().parent().addClass('has-clear');
						$this.parent().append($('<span class="clear-button"/>').click(function () {
							$(this).parent().find('.js-custom-select')[0].sumo.unSelectAll();
						}));
					}
				}
			} else {
				if (e.target.selectedIndex === 0) {
					if ($this.parent().parent().hasClass('has-clear')) {
						$this.parent().parent().removeClass('has-clear');
						$this.siblings('.clear-button')[0].remove();
					}
				} else {
					if (!$this.parent().parent().hasClass('has-clear')) {
						$this.parent().parent().addClass('has-clear');
						$this.parent().append($('<span class="clear-button"/>').click(function () {
							$(this).parent().find('.js-custom-select')[0].sumo.unSelectAll();
						}));
					}
				}
			}
		});

		$('.range-box').on('click', function (e) {
			var $this = $(this);
			if (e.target !== $this.find('.clear-button')[0]) {
				var addFocus = false;
				if (!$this.hasClass('focus')) {
					addFocus = true;
				}
				$('.range-box').removeClass('focus');
				$this.addClass('focus');

				if (addFocus) {
					$this.children('input:first-of-type').focus();
				}
			}
		});

		$('.range-box input').on('input propertychange', function (e) {
			var $this = $(this);
			$(this).siblings('label').html($this[0].parentElement.children[1].value + ' ' + $this.siblings('span:first-of-type').text() + ' ' + $this[0].parentElement.children[3].value + ' ' + $this.siblings('span:nth-of-type(2)').text());
		});

		$(document).mouseup(function (e) {
			var rangebox = $('.range-box');
			if (!rangebox.is(e.target) && rangebox.has(e.target).length === 0) {
				rangebox.removeClass('focus');
			}
		});

		if ($('.flow .gallery-item').length) {
			$('.info-gallery').lightGallery({
				selector: '.gallery-item',
				thumbnail: true,
				currentPagerPosition: 'left',
				thumbMargin: 10,
				mousewheel: true,
				autoplay: false,
				download: false,
				fullScreen: false,
				pager: false,
				zoom: false,
				hash: false,
				share: false
			});
		}

		if ($('.property-search .properties-map').length) {
			//Search page stickySidebar
			$('.property-search .properties-map').stickySidebar({
				topSpacing: 74 + 15,
				bottomSpacing: 15,
				resizeSensor: true,
				innerWrapperSelector: '.g-map'
			});
		}

		if ($('.property-gallery.enabled').length) {
			//Property show gallery init
			$('.property-gallery').lightGallery({
				selector: '.gallery-item',
				thumbnail: true,
				currentPagerPosition: 'left',
				thumbMargin: 10,
				mousewheel: true,
				autoplay: false,
				download: false,
				fullScreen: false,
				pager: false,
				zoom: false,
				hash: false,
				share: false
			});
		}

		$('.charlimited').on('keyup change paste', function (e) {
			var $this = $(this),
				tval = $this.val(),
				tlength = tval.length,
				set = $this.data('limit'),
				remain = parseInt(set - tlength);

			// $this.closest('.charlimit').text(remain+'/'+set);
			if (remain <= 0) {
				$this.val((tval).substring(0, set))
			}

			$this.parent().find('.charlimit').html((parseInt($this.val().length)) + '/' + set);
		});

		function dreamHomesSlick() {
			//dream-homes slick on mobile
			$('.dream-homes .house-cards-wrapper').each(function () {
				var $carousel = $(this);

				if ((window.matchMedia("(min-width: 992px)")).matches) {
					if ($carousel.hasClass('slick-initialized')) {
						$carousel.slick('unslick');
					}
				} else {
					if (!$carousel.hasClass('slick-initialized')) {
						$carousel.slick({
							infinite: false,
							centerMode: true,
							centerPadding: '0px',
							slidesToShow: 1,
							slidesToScroll: 1,
							dots: true,
							arrows: false,
							variableWidth: true,
							mobileFirst: true
						});
					}
				}
			});
		}

		//dream-homes slick on mobile
		$(window).on('load resize orientationchange', function () {
			dreamHomesSlick();
		});

		dreamHomesSlick();

		if ($('.file-upload-trigger').length) {

			initSortable();

            /**
             * Trigger file upload btn
             */
			$('.file-upload-trigger').click(function (e) {
				e.preventDefault();

				// if(e.target.className.indexOf('delete-image') != -1){
				//     return;
				// }
				$('#fileupload').click();
			});

            /**
             * Upload files
             */
			$('#fileupload').fileupload({
				dataType: 'json',
				type: 'POST',
				dropZone: $('.upload-block'),
				start: function (e, data) {
					$('.upload-block').addClass('d-none');
					$('.file-loader').removeClass('d-none').addClass('d-flex');
				},
				done: function (e, data) {
					if (data.result.status == 'error') {
						alert(data.result.message);
					}
					else {
						$('#uploaded-files').prepend(data.result.imageHtml);
					}

					if ($('.upload-wrapper.has-img').length) {
						$('.upload-box.first').parent().remove();
						$('.upload-box.second').parent().removeClass('d-none').addClass('d-block');
					}

					$('.file-loader').removeClass('d-flex').addClass('d-none');
					$('.upload-block').removeClass('d-none');
				}
			});
		}

		$('.missing-property-details .close-icon').click(function (e) {
			e.preventDefault();
			$('.property-details-alert').slideUp(500);
		})

		if ($('#contactForm').length) {
			$('#contactForm').submit(function (e) {
				var $form = $('#contactForm');

				$form.find('.error').removeClass('error');

				e.preventDefault();

				$.ajax({
					url: $form.attr('action'),
					method: 'POST',
					data: $form.serialize(),
					success: function (response) {
						if (response.status == 'ok') {
							toastr.success('Üzenet elküldve!');
						}
						else {
							if (response.field) {
								$('#' + response.field).addClass('is-invalid');
							}
							toastr.error(response.message && response.message != '' ? response.message : 'Hiba történt, kérjük frissítsd az oldalt és próbáld meg újra.');
						}
					},
					error: function (response) {
						if (response.status === 422) {
							var response = response.responseJSON;

							for (var k in response.errors) {
								$('#' + k).closest('.form-group').addClass('error').find('p.error-response').html(response.errors[k][0]);
							}

							if ($('.form-group.error').length > 0) {
								var first_position = $('.form-group.error').first().offset().top;
								$('html, body').animate({
									scrollTop: first_position - 100
								}, 500);
							}
							return;
						}

						if (response.message) {
							toastr.error(response.message && response.message != '' ? response.message : 'Hiba történt, kérjük frissítsd az oldalt és próbáld meg újra.');
						}
						else {
							toastr.error('Hiba történt, kérjük próbálja újra');
						}
					}
				});

				return false;
			});
		}
	});

    /**
     * Delete upload
     */
	$(document).on('click', '.delete-upload', function (e) {
		e.preventDefault();
		var $this = $(this);
		var url = $this.data('href');

		if ($('#uploaded-files').hasClass('no-delete')) {
			$this.parent().parent().remove();
			toastr.success('Kép törölve, kérjük mentse el a változtatásokat.');
			return;
		}

		$.ajax({
			url: url,
			method: 'POST',
			success: function (response) {
				if (response.status == 'ok') {
					toastr.success('Kép törölve.');
					if ($this.hasClass('user-photo')) {
						location.reload();
					}
					else {
						$this.parent().remove();
					}

				}
				else {
					toastr.error(response.message && response.message != '' ? response.message : 'Hiba történt, kérjük frissítsd az oldalt és próbáld meg újra.');
				}
			}
		});
	});

	$('.index-video-gallery').lightGallery({
		autoplayFirstVideo: true,
		youtubePlayerParams: {
			rel: 0
		}
	});

	$('.flow-item').click(function () {
		if ($(this).siblings('.flow-tip').hasClass('active')) {
			$('.flow-tip.active').removeClass('active');
		} else {
			$('.flow-tip.active').removeClass('active');
			$(this).siblings('.flow-tip').addClass('active');
		}
	});

	$(document).mouseup(function (e) {
		var flowitem = $('.flow-item');
		if (!flowitem.is(e.target) && flowitem.has(e.target).length === 0) {
			$('.flow-tip.active').removeClass('active');
		}
	});


	if ($('.template__static').length) {

		$('.template__static table').each(function (e) {
			$(this).addClass('dragscroll')
		})

		dragscroll.reset();
	}

	if ($('#accordion').length && window.location.hash && $(window.location.hash).length) {
		$($(window.location.hash).data('target')).collapse(true);
		$('HTML, BODY').animate({ scrollTop: $(window.location.hash).offset().top - 100 }, 500);
	}

})(jQuery);

function replaceAll(str, find, replace) {
	return str.replace(new RegExp(find, 'g'), replace);
}

function loader(status, selectors, submit) {
	if (status) {
		$(selectors).attr('disabled', 'disabled').addClass('disabled');
		$(selectors).find('svg').addClass('rotating').removeClass('d-none');
		if (submit != undefined && submit) {
			$(selectors).parents('form').submit();
		}
	} else {
		$(selectors).removeAttr('disabled').removeClass('disabled');
		$(selectors).find('svg').addClass('d-none').removeClass('rotating');
	}
}
