/**
 * Poll new notifications
 * @param url
 */
var lastNotificationCheck = null;
var notificationsShown = [];

function pollNotifications() {

    if ($('#notifications').find('.dropdown-item').length) {
        $('#notifications').find('.dropdown-item').each(function () {
            if ($(this).data('notification-id')) {
                notificationsShown.push($(this).data('notification-id'));
            }
        })
    }

    $.ajax({
        url: notificationUrl,
        type: 'POST',
        data: {
            last_check: lastNotificationCheck
        },
        success: function (response) {
            if (response.status == 'ok') {
                if (response.last_check) {
                    lastNotificationCheck = response.last_check;
                }

                if (response.notificationCount > 0) {

                    $('.default-notification').remove();
                    $('#notifications').parent().find('.nav-link').addClass('active');
                    $('#notifications').parent().find('.notification-badge').removeClass('d-none').html(response.notificationCount);

                    for (var k in response.notifications) {
                        if (notificationsShown.indexOf(response.notifications[k].id) == -1) {
                            $('#notifications').prepend(response.notifications[k].html);
                            notificationsShown.push(response.notifications[k].id);
                            initNotificationsSeen();
                        }
                    }
                }

                if (parseInt(response.messageCount) > 0) {
                    $('#messageNotifications').find('.notification-badge').removeClass('d-none').html(response.messageCount);
                }else{
                    $('#messageNotifications').find('.notification-badge').addClass('d-none');
                }

                if (typeof (window.store) != 'undefined' && response.messages.length) {
                    for (var k in response.messages) {
                        window.store.dispatch({
                            type: 'UPDATE_UNSEEN_MESSAGES_COUNT',
                            roomType: response.messages[k].roomType,
                            id: response.messages[k].id,
                            count: response.messages[k].count
                        });
                    }
                }
            }

            setTimeout(pollNotifications, 30000);
        },
        error: function (response) {
            if (response.last_check) {
                lastNotificationCheck = response.last_check;
            }
            setTimeout(pollNotifications, 30000);
        }
    });
}

function initNotificationsSeen() {
    $('.mark-notification-seen').click(function (e) {
        e.preventDefault();
        var $this = $(this);

        $this.parent().slideUp();
        var count = parseInt($('#notifications').parent().find('.notification-badge').html());

        count = count - 1;

        $('#notifications').parent().find('.notification-badge').html(count);

        if (count == 0) {
            $('#notifications').parent().find('.notification-badge').addClass('d-none');
        }

        $.ajax({
            url: $this.data('href'),
            method: 'post',
            success: function (response) {
                $this.parent().remove();
                return;
            },
            error: function (response) {
                console.log(response);
                return;
            }
        })
    });
}

$(document).ready(function () {

    /**
     * Mark notifications budget hidden
     */
    $('#openNotifications').click(function(){
        $.ajax({
            url: notificationBudgetHiddenUrl,
            type: 'POST',
            success: function(response){
                if(response.status == 'ok'){
                    $('#notifications').parent().find('.notification-badge').addClass('d-none');
                }
            },
            error: function(response){
                console.log(response);
            }
        })
    })


    if ($('.mark-notification-seen').length) {
        initNotificationsSeen();
    }
});

function updateChatNotificationCount(messageRoomId) {
    var $notificationBadge = $('#messageNotifications').find('.notification-badge');

    var notificationCount = parseInt($notificationBadge.html());

    notificationCount = notificationCount - 1;

    if (notificationCount == 0) {
        $notificationBadge.html('0').addClass('d-none');
    }
    else {
        $notificationBadge.html(notificationCount);
    }

    return true;
}

window.updateChatNotificationCount = updateChatNotificationCount;
