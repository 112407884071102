(function($) {
	"use strict";
	$(function() {

		//Timetable slick
		if($('.timetable-wrapper.public').length){
			$('.timetable-wrapper.public').slick({
				infinite: false,
				slidesToShow: 5,
				slidesToScroll: 5,
				arrows: true,
				dots: false
			});

			$('.timetable-buttons .btn').on('click', function () {
				$('.day-wrapper .hour').removeClass('selected');
				$('.timetable-buttons .btn').removeClass('selected');
				$('.chosen-infos .time-text').addClass('d-none');
				$('.js-btn-next').addClass('disabled');

				var $this = $(this);
				$this.addClass('selected');
				$('.timetable-wrapper').attr('data-value', $this.data('value'));
				$('.chosen-infos .location').text($this.text());
				$('.chosen-infos .location-text').removeClass('d-none');
			});

			$('.day-wrapper .hour').on('click', function () {
				var $this = $(this);
				if ($('.timetable-wrapper').attr('data-value') !== '') {
					if ($this.hasClass($('.timetable-wrapper').attr('data-value'))) {
						$('.day-wrapper .hour').removeClass('selected');
						$this.addClass('selected');
						$('.chosen-infos .time').text($this.siblings('.headline').find('.datetime').eq(0).text() + ' ' + $this.siblings('.headline').find('.dayofweek').eq(0).text() + ', ' + $this.text());
						$('.chosen-infos .time-text').removeClass('d-none');
						$('.js-btn-next').removeClass('disabled');
					} else {
						toastr.error('Az időpont nem elérhető, válassz másikat!');
					}
				} else {
					toastr.error('Válassz először helyszínt');
				}
			});
		}

	});
})(jQuery);