(function($) {
	"use strict";
	$(function() {

		if($('.chat').length) {
			$('.js-chat-list').on('click', function () {
				var tab = $('#nav-chat-list');
				if(tab.hasClass('show-on-mobile')) {
					tab.removeClass('show-on-mobile').tab('dispose');
					$('body').removeClass('menu-open');
				} else {
					tab.addClass('show-on-mobile').tab('show');
					$('body').addClass('menu-open');
				}
			});
		}
	});
})(jQuery);
