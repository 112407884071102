(function($) {
    $(function() {

        // CUSTOM NUMBER INPUT CHANGES
        $('.cover__slider').slick({
            slideToShow: 1,
            arrows: false,
            dots: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: $('.cover__slider').data('autoplay-speed')
        });
    });
})(jQuery);
